import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { ButtonComponent } from '../../components/button/button.component';
import { LanguageButtonComponent } from '../../components/language-button/language-button.component';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { UserService } from '../../services/user/user.service';
import { ViewPickerService } from '../../services/view-picker.service';
import { SupportedLanguage, SupportedMarket } from '../../types/market.types';
import { User } from '../../types/user.types';

@Component({
	selector: 'cramo-confirm-market-change-page',
	templateUrl: './confirm-market-change-page.component.html',
	styleUrls: ['./confirm-market-change-page.component.scss'],
	standalone: true,
	imports: [LanguageButtonComponent, NgIf, ButtonComponent, AsyncPipe],
})
export class ConfirmMarketChangePageComponent {
	public user$: Observable<User>;
	public currentLanguage: SupportedLanguage;
	public currentMarket: string;

	public viewData$: Observable<{ user: User; translations: ConfirmMarketSwitchTranslations }>;

	constructor(
		private viewPickerService: ViewPickerService,
		private userService: UserService,
		private marketService: MarketService,
		private epiService: EpiService,
	) {
		this.viewData$ = combineLatest({ user: this.userService.user$, translations: this.getTranslations() });

		this.user$ = this.userService.user$;
		this.currentLanguage = this.marketService.currentLanguage;
		this.currentMarket = this.marketService.currentMarket;
	}

	public stayOnMarket() {
		this.viewPickerService.setView('onboarding');
	}

	public goToDefaultMarket(marketId: SupportedMarket) {
		this.marketService.updateMarket(marketId);
	}

	private getTranslations(): Observable<ConfirmMarketSwitchTranslations> {
		return this.epiService.appData$.pipe(
			switchMap((appData) =>
				combineLatest({
					header: of(appData?.onboarding?.confirmMarketChangeHeader ?? ''),
					description: this.parseDescription(appData?.onboarding?.confirmMarketChangeDescription ?? ''),
					returnToDefaultMarketButtonText: this.parseReturnToDefaultMarketButtonText(
						appData?.onboarding?.confirmMarketChangeReturnToDefaultMarketButtonText ?? '',
					),
					startOnBoardingButtonText: of(appData?.onboarding?.confirmMarketChangeStartOnboardingButtonText ?? ''),
				}),
			),
		);
	}

	private parseDescription(description: string): Observable<string> {
		return combineLatest({
			user: this.user$,
			markets: this.marketService.markets$,
			marketLabels: this.marketService.marketLabels$,
		}).pipe(
			map(({ user, markets, marketLabels }) => {
				const customerName = user.CustomerInfo?.CompanyName;
				const currentMarket = markets.find((market) => market.ID === this.currentMarket)!;
				const currentMarketLabel = marketLabels[this.currentLanguage][currentMarket?.ID];
				const defaultMarket = markets.find((market) => market.ID === user.CustomerInfo?.MarketId)!;
				const defaultMarketLabel = marketLabels[this.currentLanguage][defaultMarket?.ID];

				return description
					.replace(/%current_market%/g, currentMarketLabel)
					.replace(/%customer_name%/g, customerName!)
					.replace(/%default_market%/g, defaultMarketLabel);
			}),
		);
	}

	private parseReturnToDefaultMarketButtonText(buttonText: string): Observable<string> {
		return combineLatest({
			user: this.user$,
			markets: this.marketService.markets$,
			marketLabels: this.marketService.marketLabels$,
		}).pipe(
			map(({ user, markets, marketLabels }) => {
				const defaultMarket = markets.find((market) => market.ID === user.CustomerInfo?.MarketId)!;
				const defaultMarketLabel = marketLabels[this.currentLanguage][defaultMarket?.ID];

				return buttonText.replace(/%default_market%/g, defaultMarketLabel);
			}),
		);
	}
}

interface ConfirmMarketSwitchTranslations {
	header: string;
	description: string;
	returnToDefaultMarketButtonText: string;
	startOnBoardingButtonText: string;
}
