import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ClickOutsideDirective } from '../../../../directives/click-outside.directive';
import { EpiService } from '../../../../services/epi/epi.service';
import { MarketService } from '../../../../services/market.service';
import { UserService } from '../../../../services/user/user.service';
import { AppData } from '../../../../types/translations.types';
import { ContactUsComponent } from '../../../contact-us/contact-us.component';
import { FlipPanelComponent } from '../../../flip-panel/flip-panel.component';
import { IconComponent } from '../../../icon/icon.component';
import { LinkComponent } from '../../../link/link.component';
import { Menu, MenuChild } from '../../types/menu.types';

@Component({
	selector: 'cramo-menu-mobile',
	templateUrl: './menu-mobile.component.html',
	styleUrls: ['./menu-mobile.component.scss'],
	standalone: true,
	imports: [CommonModule, LinkComponent, IconComponent, FlipPanelComponent, ContactUsComponent, ClickOutsideDirective],
})
export class MenuMobileComponent implements OnInit {
	private epiService = inject(EpiService);
	private marketService = inject(MarketService);
	private userService = inject(UserService);

	protected isMenuMenuVisible = toSignal(this.epiService.isMainMenuVisible$, { initialValue: false });
	protected isLoggedIn = toSignal(this.userService.isLoggedIn$);
	protected appData: AppData;
	protected updatedMenu: MenuChild[];
	protected currentLanguage: string;
	protected openMainTab: MenuChild | null = null; // top level tabs
	protected openSecondTabs: Record<string, boolean> = {}; // second level tabs
	protected openTabs: Record<string, boolean> = {}; // low level tabs

	private menu: Menu;

	constructor() {
		this.menu = this.epiService.menu;
		this.appData = this.epiService.appData;
		this.currentLanguage = this.marketService.currentLanguage;
	}

	ngOnInit(): void {
		this.updatedMenu = this.repairMenu(this.menu);
	}

	// add the separate product list to children of products
	private repairMenu(menu: Menu): MenuChild[] {
		const productList = menu.Category.Children;
		const updatedArray = menu.MenuItems.map((item) => {
			if (item.Name === menu.Category.Name)
				return {
					...item,
					Children: productList,
				};
			return item;
		});
		return updatedArray;
	}

	public toggleTopLevel(menuItem: MenuChild) {
		if (menuItem.Name === this.openMainTab?.Name) {
			this.openMainTab = null;
		} else {
			this.openMainTab = menuItem;
		}
	}

	public hideMenu() {
		this.epiService.changeMainMenuVisibility('close');
	}

	public onMainMenuClick(item: MenuChild) {
		const newState = !this.openSecondTabs[item.Name];
		for (const key in this.openSecondTabs) {
			this.openSecondTabs[key] = false;
		}
		this.openSecondTabs[item.Name] = newState;
	}

	public onMenuClick(item: MenuChild, open = true) {
		if (item.Children && item.Children.length > 0 && open) {
			this.openTab(item);
		}
	}

	private openTab(category: MenuChild) {
		if (category.Children.length > 0) {
			const newState = this.openTabs[category.Name] !== true;
			this.closeAll();
			this.openTabs[category.Name] = newState;
		} else {
			this.closeAll();
		}
	}

	private closeAll() {
		for (const key in this.openTabs) {
			this.openTabs[key] = false;
		}
	}
}
