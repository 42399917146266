<div class="header-container">
	@if (isPunchout$ | async) {
		<cramo-punchout-banner />
	}
	<div class="header-area page-wrapper frow-between-center page-padding" [ngClass]="{ 'scroll-down': scrollDown }">
		@if (isMobile) {
			<div class="hamburger-wrapper frow-center-center order-menu" (click)="toggleMainMenuVisibility()">
				<ng-container>
					@switch (isMainMenuVisible()) {
						@case (false) {
							<mat-icon fontIcon="menu" />
						}
						@case (true) {
							<mat-icon fontIcon="close" />
						}
					}
				</ng-container>
			</div>
		}

		<div class="frow-start-center order-logo header-logo" data-test-id="header-logo">
			<a class="header-logo__link" [routerLink]="['/' + currentLanguage]">
				<img class="header-logo__img" src="/assets/images/cramo-logo.svg" alt="Cramo" />
			</a>
		</div>

		<!-- search input -->
		<cramo-search-header-input class="header-search-input order-search" (click)="hideMenu()" />

		<div class="order-icons">
			@if (!isMobile) {
				<!-- link to depot -->
				<cramo-button
					[routerLink]="[currentLanguage + '/depot']"
					buttonStyle="basic"
					prefixIcon="location_on"
					[iconSize]="24"
					buttonColor="primary"
					data-test-id="header-desktop-button-depot"
				/>

				<!-- link to contact -->
				<cramo-flip-panel [minWidth]="250" [alignLeft]="false" (click)="hideMenu()">
					<cramo-button
						trigger
						buttonStyle="basic"
						prefixIcon="smartphone"
						[iconSize]="24"
						buttonColor="primary"
						data-test-id="header-desktop-button-contact"
					/>
					<cramo-contact-us body />
				</cramo-flip-panel>
			}

			@if (isLoggedIn$ | async) {
				@if (featureToggles().other.isNewCartEnabled) {
					<cramo-button
						*cramoHasPermission="['RENT_PICKUP', 'RENT_TRANSPORT', 'RENT_BOX_PICKUP']"
						[routerLink]="currentLanguage + '/cart'"
						buttonColor="primary"
						prefixIcon="shopping_cart"
						[badge]="cartItemCount() | maxNumber: 99"
						[iconSize]="22"
						[isLoading]="cartItemCount() === undefined"
						data-test-id="header-button-cart"
					/>
				} @else {
					<cramo-button
						*cramoHasPermission="['RENT_PICKUP', 'RENT_TRANSPORT', 'RENT_BOX_PICKUP']"
						[routerLink]="currentLanguage + '/cart'"
						buttonColor="primary"
						prefixIcon="shopping_cart"
						[badge]="cartItems()!.cartCount | maxNumber: 99"
						[iconSize]="22"
						[isLoading]="cartItems()!.isLoading"
						data-test-id="header-button-cart"
					/>
				}

				@if (featureToggles().other.areNotificationsEnabled && !isMobile) {
					<cramo-button
						*cramoHasPermission="'LIST_ORDER_LINES'"
						[routerLink]="currentLanguage + '/notifications'"
						[badge]="notificationCount() | maxNumber: 99"
						[isLoading]="notificationCount() === null"
						buttonColor="primary"
						prefixIcon="notifications"
						[iconSize]="22"
						data-test-id="header-button-notifications"
					/>
				}

				<cramo-button
					[text]="appData().login.myAccountText"
					suffixIcon="person"
					buttonColor="primary"
					(onclick)="toggleUserSideNav()"
					[iconSize]="22"
					data-test-id="header-button-myaccount"
				/>
			} @else {
				@if (featureToggles().other.isCreateNewUserButtonEnabled) {
					<cramo-button
						[text]="isMobile ? appData().global.registerShort : appData().global.registerLong"
						buttonStyle="basic"
						buttonColor="primary"
						[routerLink]="registrationLink"
						(onclick)="hideMenu()"
					/>
				}

				@if (featureToggles().other.isLoginEnabled) {
					<cramo-button
						buttonStyle="flat"
						buttonColor="primary"
						[text]="appData().login.loginButtonText"
						[suffixIcon]="isMobile ? null : 'login'"
						(onclick)="login($event)"
						data-test-id="header-button-login"
					/>
				}
			}
		</div>
	</div>

	<cramo-menu />
</div>

@if (showMenu && isMobile) {
	<!-- overlay for mobile menu -->
	<div class="menu-overlay" (click)="hideMenu()"></div>
}
