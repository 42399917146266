@if (currentViewState$ | async; as currentViewState) {
	<ng-container>
		@if (currentViewState === 'marketSelector') {
			<cramo-select-market />
		}

		@if (currentViewState === 'loading' || isLoading) {
			<cramo-loading-area [title]="(loadingSlogan$ | async)!" />
		}

		@if (currentViewState === 'confirmMarketSwitch') {
			<cramo-confirm-market-change-page />
		}

		@if (currentViewState === 'noAvailableCustomers') {
			<cramo-no-available-customers-page />
		}

		@if (currentViewState === 'onboarding') {
			<cramo-onboarding-page />
		}

		@if (currentViewState === 'landingPage' && !isLoading) {
			<cramo-header />
			@if (isLoggedIn$ | async) {
				<cramo-user-side-nav />
			}
			<router-outlet #outlet="outlet" />
			<cramo-footer />
		}
	</ng-container>
}
<ng-container *ngIf="user$ | async as user; else noUser">
	@if (environment.injectLiveChat) {
		<livechat-widget
			license="12340707"
			group="0"
			[customerName]="user.FirstName + ' ' + user.LastName"
			[customerEmail]="user.Email"
		/>
	}
</ng-container>

<ng-template #noUser>
	@if (environment.injectLiveChat) {
		<livechat-widget license="12340707" group="0" />
	}
</ng-template>
