<div class="confirm-market-change-container" *ngIf="viewData$ | async as viewData">
	<cramo-language-button />

	<img src="/assets/images/cramo-logo.svg" alt="Cramo" width="200" height="34" class="cramo-logo" />

	<h2>{{ viewData.translations.header }}</h2>

	<p>{{ viewData.translations.description }}</p>

	<div class="buttons">
		<cramo-button
			text="{{ viewData.translations.returnToDefaultMarketButtonText }}"
			buttonColor="primary"
			(onclick)="goToDefaultMarket(viewData.user.CustomerInfo!.MarketId)"
		/>
		<cramo-button
			text="{{ viewData.translations.startOnBoardingButtonText }}"
			buttonColor="primary"
			(onclick)="stayOnMarket()"
		/>
	</div>
</div>
