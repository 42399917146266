<cramo-punchout-banner *ngIf="isPunchout$ | async" />

<div class="onboarding-container">
	<cramo-language-button />

	<cramo-loading-area zIndex="2" *ngIf="isLoadingView" [title]="appData().onboarding.loadingText" />

	<img src="/assets/images/cramo-logo.svg" alt="Cramo" width="200" height="34" class="cramo-logo" />

	<mat-vertical-stepper linear #stepper>
		<ng-template matStepperIcon="edit">
			<mat-icon>done</mat-icon>
		</ng-template>

		<mat-step [label]="appData().onboarding.startStepText" color="primary">
			<div class="step">
				<h2>{{ appData().onboarding.startHeader }}</h2>
				<p>{{ appData().onboarding.startDescription }}</p>
				<cramo-button
					buttonColor="primary"
					(onclick)="isCustomerSet ? goToDepot() : goForward()"
					[text]="appData().onboarding.startButtonText"
					suffixIcon="arrow_forward"
					[innerId]="'onboarding-button-start'"
				/>
			</div>
		</mat-step>

		<mat-step [label]="appData().onboarding.selectCustomerStepText" color="primary" [completed]="isCustomerSet">
			<div class="step" [ngClass]="{ disabled: isLoadingSetCustomer }">
				<h3>{{ appData().onboarding.selectCustomerHeader }}</h3>
				<p>{{ appData().onboarding.selectCustomerDescription }}</p>
				<p>{{ appData().onboarding.selectCustomerCustomerNotFoundOnMarketDescription }}</p>
				<cramo-autocomplete
					[ngClass]="{ disabled: isPunchout }"
					[label]="appData().onboarding.selectCustomerAutocompleteLabel"
					[options]="customerOptions()"
					[isLoading]="customerOptions().length === 0"
					[control]="customerForm"
					data-test-id="onboarding-input-select-customer"
				/>
				<mat-error *ngIf="didCustomerSelectFail">{{ appData().onboarding.selectCustomerErrorText }}</mat-error>
				<cramo-button
					(onclick)="isPunchout ? goToDepot() : onCustomerSelect()"
					[text]="appData().onboarding.selectCustomerButtonText"
					buttonColor="primary"
					type="submit"
					suffixIcon="arrow_forward"
					[innerId]="'onboarding-button-select-customer'"
				/>
			</div>
		</mat-step>

		<mat-step [label]="appData().onboarding.selectDepotStepText" color="primary" [completed]="isDepotSet">
			<div class="step" [ngClass]="{ disabled: isLoadingSetDepot }">
				<h3>{{ appData().onboarding.selectDepotHeader }}</h3>
				<p>{{ appData().onboarding.selectDepotDescription }}</p>
				<p>{{ appData().onboarding.selectDepotDepotNotFoundOnMarketDescription }}</p>
				<cramo-autocomplete
					[label]="appData().onboarding.selectDepotAutocompleteLabel"
					[options]="depotOptions()"
					[isLoading]="depotOptions().length === 0"
					[control]="depotForm"
					data-test-id="onboarding-input-select-depot"
				/>
				<mat-error *ngIf="didDepotSelectFail">{{ appData().onboarding.selectDepotErrorText }}</mat-error>
				<cramo-button
					(onclick)="setDepot()"
					[text]="appData().onboarding.selectDepotButtonText"
					buttonColor="primary"
					type="submit"
					suffixIcon="arrow_forward"
					[innerId]="'onboarding-button-select-depot'"
				/>
			</div>
		</mat-step>

		<ng-container *cramoHasPermission="'CREATE_JOB_SITES'">
			@if (jobsites()?.length === 0) {
				<mat-step optional [label]="appData().onboarding.createJobsiteHeader" color="primary">
					<div class="step">
						<h3 data-testid="onboarding-title-create-jobsite">{{ appData().onboarding.createJobsiteTitle }}</h3>
						<p>{{ appData().onboarding.createJobsiteText }}</p>
						<p>{{ appData().onboarding.createJobsitePreamble }}</p>

						@if (createdJobsite) {
							<cramo-card class="jobsite-created-card" appearance="outlined" [hideShadow]="true">
								<div class="jobsite-created-card-title">
									<cramo-icon iconName="check_circle" [size]="30" />
									<span>{{ appData().onboarding.createJobsiteSuccessTitle }}</span>
								</div>
								<div class="jobsite-created-card-body">
									<div class="jobsite-created-card-container">
										<span class="jobsite-created-card-label">{{ appData().onboarding.jobsiteNameHeader }}</span>
										<span>{{ this.createdJobsite.Name }}</span>
									</div>
									<div class="jobsite-created-card-container">
										<span class="jobsite-created-card-label">{{ appData().onboarding.invoiceReferenceHeader }}</span>
										<span>{{ this.createdJobsite.ProjectId }}</span>
									</div>
									<div class="jobsite-created-card-container">
										<span class="jobsite-created-card-label">{{ appData().onboarding.jobsiteAddressHeader }}</span>
										<span>{{ this.createdJobsite.Address.StreetAddress }} </span>
										<div>
											<span class="jobsite-created-card-postal-code-value">{{
												this.createdJobsite.Address.PostalCode
											}}</span>
											<span>{{ this.createdJobsite.Address.City }}</span>
										</div>
									</div>
								</div>
							</cramo-card>
							<cramo-button
								(onclick)="goForward()"
								[text]="appData().onboarding.completeButtonText"
								buttonColor="primary"
								suffixIcon="arrow_forward"
								[innerId]="'onboarding-button-jobsite-created'"
							/>
						} @else {
							<cramo-create-jobsite-form
								(jobsiteCreated)="onJobsiteCreated($event)"
								[isFavoriteJobsiteCheckboxHidden]="true"
							/>
							<cramo-button
								[buttonStyle]="'basic'"
								[buttonColor]="'primary'"
								[isDisabled]="isAddingJobsite"
								[suffixIcon]="'keyboard_double_arrow_right'"
								[text]="appData().onboarding.skipForNowHeader"
								(onclick)="skipJobsiteStepForNow()"
								[innerId]="'onboarding-button-skip'"
							/>
						}
					</div>
				</mat-step>
			}
		</ng-container>
		<mat-step [label]="appData().onboarding.completeStepText" color="primary">
			<div class="step">
				<h3>{{ appData().onboarding.completeHeader }}</h3>
				<p>{{ appData().onboarding.completeDescription }}</p>
				<cramo-button
					(onclick)="handleOnboardingDone()"
					[text]="appData().onboarding.completeButtonText"
					buttonColor="primary"
					suffixIcon="arrow_forward"
					[innerId]="'onboarding-button-finish'"
				/>
			</div>
		</mat-step>
	</mat-vertical-stepper>
</div>
