import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { EpiService } from '../../services/epi/epi.service';
import { PunchoutService } from '../../services/punchout.service';
import { PunchBackMessage } from '../../types/punchout.types';

@Component({
	selector: 'cramo-punchout-banner',
	templateUrl: './punchout-banner.component.html',
	styleUrls: ['./punchout-banner.component.scss'],
	standalone: true,
	imports: [],
})
export class PunchoutBannerComponent implements OnInit {
	constructor(
		private epiService: EpiService,
		private punchoutService: PunchoutService,
	) {}

	public punchoutBannerText = 'You are currently renting in PunchOut mode.';
	public punchoutCancelLinkText = 'Cancel and return';

	@ViewChild('punchBackform')
	private punchBackform: ElementRef<HTMLFormElement>;

	ngOnInit(): void {
		this.punchoutBannerText = this.epiService?.appData?.login?.punchoutBannerText ?? this.punchoutBannerText;
		this.punchoutCancelLinkText =
			this.epiService?.appData?.login?.punchoutCancelLinkText ?? this.punchoutCancelLinkText;
	}

	protected cancelPunchout() {
		this.punchoutService
			.cancelPunchout()
			.pipe(take(1))
			.subscribe((msg: PunchBackMessage) => {
				this.punchBackform.nativeElement.action = msg.url;
				this.punchBackform.nativeElement.submit();
			});
	}
}
