import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MarketService } from '../../services/market.service';
import { MetaService } from '../../services/meta.service';
import { Market } from '../../types/market.types';
import { isLocalhost } from '../../utils/location';

@Component({
	selector: 'cramo-select-market',
	templateUrl: './select-market.component.html',
	styleUrls: ['./select-market.component.scss', '../../../scss/cta-styles.scss'],
	standalone: true,
	imports: [AsyncPipe],
})
export class SelectMarketComponent implements OnInit {
	public markets$: Observable<Market[]>;

	constructor(
		private metaService: MetaService,
		marketService: MarketService,
	) {
		this.markets$ = marketService.markets$;
	}

	ngOnInit() {
		this.setMetaInfo();
	}

	public createMarketUrl(market: Market) {
		const marketID = market.ID.toLowerCase();

		if (isLocalhost()) {
			return `https://www.cramo.${marketID}/${market.DefaultLanguage}`;
		}

		const host: string = window.location.host;
		const hostArray: string[] = host.split('.');
		hostArray[hostArray.length - 1] = marketID;
		hostArray[hostArray.length - 2] = hostArray[hostArray.length - 2].replace('group', '');

		return `https://${hostArray.join('.')}/${market.DefaultLanguage}`;
	}

	private setMetaInfo() {
		const title = 'CRAMO - Full service renting partner';
		const description = 'CRAMO - Equipment rental services in 11 countries';
		const metaTags = [
			{ key: 'description', value: description },
			{ key: 'og:description', value: description },
		];
		this.metaService.setMeta(title, metaTags);
	}
}
