import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { map } from 'rxjs';
import { MarketService } from '../../services/market.service';
import { SupportedLanguage } from '../../types/market.types';

@Component({
	selector: 'cramo-language-button',
	templateUrl: './language-button.component.html',
	styleUrls: ['./language-button.component.scss'],
	standalone: true,
	imports: [MatButtonModule],
})
export class LanguageButtonComponent {
	public viewData: Signal<{ flagUrl: string; language: SupportedLanguage }>;

	constructor(private marketService: MarketService) {
		const viewData$ = this.marketService.markets$.pipe(
			map((markets) => {
				const { currentLanguage, currentMarket } = this.marketService;
				const marketInfo = markets.find((market) => market.ID === this.marketService.currentMarket);

				// Works only if the market has two available languages.
				const languageToChangeTo = marketInfo!.Languages.find((language) => language !== currentLanguage);

				const flag = languageToChangeTo === 'en' ? 'GB' : currentMarket;

				return {
					flagUrl: `assets/icons/flags/${flag}.svg`,
					language: languageToChangeTo as SupportedLanguage,
				};
			}),
		);

		this.viewData = toSignal(viewData$, { requireSync: true });
	}

	public setLanguage(language: SupportedLanguage) {
		this.marketService.updateLanguage(language);
	}
}
