import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

export class EcomRouteReuseStrategy extends BaseRouteReuseStrategy {
	constructor() {
		super();
	}

	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
		if (future.data.forceReloadOnChange === true) {
			return false;
		} else {
			return future.routeConfig === curr.routeConfig || future.data.forceReloadOnChange;
		}
	}
}
