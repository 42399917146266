<div class="select-market-popup-container frow-start-stretch">
	<div class="pop-wrapper">
		<div class="header-area frow-start-center">
			<div class="header-logo no-outline" routerLink=""></div>
		</div>
		<div class="welcome-cta-container fcolumn-center-center">
			<div class="market-container">
				<ul class="list">
					@for (market of markets$ | async; track $index) {
						<li class="list__item">
							<a [href]="createMarketUrl(market)" class="market-item" target="_self">
								<div class="fcolumn-center-center">
									<img src="assets/icons/flags/{{ market.ID }}.svg" class="market-item__image" [alt]="market.Label" />
									<span class="market-item__title">{{ market.Name }}</span>
								</div>
							</a>
						</li>
					}

					<li class="list__item list__item--cramo-group">
						<a href="https://group.boels.com/en/about-boels/about-cramo/" target="_blank">
							<span>Go to Cramo Group</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</div>
