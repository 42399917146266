import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { MarketService } from '../services/market.service';

export const marketInterceptor: HttpInterceptorFn = (req, next) => {
	const { currentMarket } = inject(MarketService);
	const modifiedUrl = req.url.replace('/webapi', `/${currentMarket}/webapi`);
	const modifiedReq = req.clone({ url: modifiedUrl });
	return next(modifiedReq);
};
