@let footerDataValue = footerData();

<div id="footerContainer" class="footer-container" data-test-id="footer-container">
	<div class="page-wrapper">
		<div class="page-padding">
			@if (footerDataValue) {
				<div class="container">
					<div class="row">
						<div class="col-lg-9">
							<div class="row link-list">
								@for (link of footerDataValue.FooterMenuLinks; track $index) {
									<div class="col-sm-6 col-lg-3">
										<cramo-link [url]="link.LinkUrl">
											<span>{{ link.Name }}</span>
										</cramo-link>
									</div>
								}

								<div class="col-sm-6 col-lg-3">
									<button
										id="cookie-settings-button"
										(click)="handleCookieSettingsClick()"
										[attr.aria-label]="this.appData.home.cookieConsentAriaLabel"
									>
										{{ this.appData.global.cookieSettingsLinkText }}
									</button>
								</div>
								@if ((footerDataValue?.FooterMenuLinks ?? []).length % 2) {
									<div class="col-sm-6 col-lg-3 p-0 pt-2"></div>
								}
							</div>
						</div>
						@if (combinedMarketData()) {
							<div class="col-lg-3">
								<div class="row">
									@if (currentMarket.Languages.length > 1) {
										<button class="language-select" (click)="toggleLanguage()">
											@if (currentLanguage === 'en') {
												<ng-container>{{ currentMarket.ChooseLanguageLabel }}</ng-container>
											} @else {
												<ng-container>Change to English language</ng-container>
											}
										</button>
									}

									<div class="market-select-container">
										<select (change)="selectMarket($event)" class="market-select" data-testid="market-select">
											@for (market of combinedMarketData().markets; track $index) {
												<option [value]="market.ID" [selected]="currentMarket.ID === market.ID">
													{{ market.Label }}
												</option>
											}
										</select>
										<cramo-icon class="arrow-icon" iconName="expand_more" [size]="20" iconDisplay="inline" />
									</div>
								</div>
							</div>
						}
					</div>
					<div class="row">
						<div class="col-lg-3 offset-lg-9 mt-2">
							@for (mediaLink of footerDataValue.SocialMediaLinks; track $index) {
								<cramo-link [url]="mediaLink.LinkUrl">
									@if (mediaLink.Type === 'Facebook') {
										<img
											src="/assets/icons/social-media/facebook.webp"
											class="footer-icon"
											alt="Visit Cramo-page on Facebook"
										/>
									} @else if (mediaLink.Type === 'Instagram') {
										<img
											src="/assets/icons/social-media/instagram.webp"
											class="footer-icon"
											alt="Visit Cramo-page on Instagram"
										/>
									} @else if (mediaLink.Type === 'Linkedin') {
										<img
											src="/assets/icons/social-media/linkedin.webp"
											class="footer-icon"
											alt="Visit Cramo-page on LinkedIn"
										/>
									} @else if (mediaLink.Type === 'Youtube') {
										<img
											src="/assets/icons/social-media/youtube.webp"
											class="footer-icon"
											alt="Visit Cramo-page on Youtube"
										/>
									} @else if (mediaLink.Type === 'X') {
										<img
											src="/assets/icons/social-media/twitter.webp"
											class="footer-icon"
											alt="Visit Cramo-page on Twitter"
										/>
									}
								</cramo-link>
							}
						</div>
					</div>

					<div class="footer-copyright">
						<span>{{ appData?.home?.copyright }}</span>
						@for (infoLink of footerDataValue.FooterInfoLinks; track $index) {
							<div>
								<cramo-link [url]="infoLink.LinkUrl" class="main-link info-link">
									<span>{{ infoLink.Name }}</span>
								</cramo-link>
							</div>
						}
					</div>
				</div>
			}
		</div>
	</div>
</div>
