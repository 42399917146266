import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MarketService } from '../services/market.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
	private marketService = inject(MarketService);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const language = this.marketService.currentLanguage ?? this.marketService.getLanguageFromUrl();
		const clonedReq = req.clone({
			headers: req.headers.set('Accept-Language', language ?? navigator.languages.toString()),
		});

		return next.handle(clonedReq);
	}
}
