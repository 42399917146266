import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import { MarketService } from '../services/market.service';
import { isSupportedLanguage } from '../types/market.types';
import { isDotComDomain } from '../utils/location';

export const routePathGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router);
	const marketService = inject(MarketService);

	if (isDotComDomain()) return of(true);

	if (state.url === '/') {
		return router.navigate([marketService.currentLanguage]).then(() => true);
	}

	if (!isSupportedLanguage(route.params.lang)) {
		// If the current route's language parameter is not valid, prepend the language
		// parameter for the current market before the original path. This transforms
		// the URL to include the appropriate language parameter.
		// Example: cramo.se/whatever -> cramo.se/sv/whatever (when the current market's language is 'sv')
		const path = `/${marketService.marketLanguageMap[marketService.currentMarket]}${state.url}`;
		return router.navigateByUrl(path).then(() => true);
	}

	return of(true);
};
