import { inject } from '@angular/core';

import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { DepotService } from '../services/depot/depot.service';

/**
 * Fetch all depots before proceeding
 */
export const depotGuard: CanActivateFn = () => {
	const depotService = inject(DepotService);

	return depotService.depots$.pipe(
		map((data) => {
			if (data) {
				return true;
			} else {
				return false;
			}
		}),
	);
};
