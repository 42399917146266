import { NgFor } from '@angular/common';
import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, map, tap } from 'rxjs';
import { FooterDto } from '../../services/epi/epi.model';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { isSupportedLanguage, isSupportedMarket, Market } from '../../types/market.types';
import { AppData } from '../../types/translations.types';
import { IconComponent } from '../icon/icon.component';
import { LinkComponent } from '../link/link.component';

declare global {
	interface Window {
		Cookiebot: {
			renew(): void;
		};
	}
}
@Component({
	selector: 'cramo-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	standalone: true,
	imports: [IconComponent, LinkComponent, NgFor],
})
export class FooterComponent {
	public appData: AppData;
	public currentMarket: Market;
	public currentLanguage: string;
	public footerData: Signal<FooterDto | undefined>;
	public combinedMarketData: Signal<{
		languageNames: { [shortName: string]: string };
		markets: Market[];
	}>;

	constructor(
		private epiService: EpiService,
		private marketService: MarketService,
	) {
		this.currentLanguage = this.marketService.currentLanguage;
		this.appData = epiService.appData;
		const languageNames$ = this.marketService.languageNames$;

		this.footerData = toSignal(this.epiService.getFooterData());
		const markets$ = this.marketService.markets$.pipe(
			tap((markets) => {
				this.currentMarket = this.getMarketData(markets);
			}),
		);

		this.combinedMarketData = toSignal(
			combineLatest([languageNames$, markets$]).pipe(
				map(([languageNames, markets]) => {
					return { languageNames, markets };
				}),
			),
			{ requireSync: true },
		);
	}

	public selectMarket(event: Event) {
		const { value } = event.target as HTMLSelectElement;

		if (!isSupportedMarket(value)) return console.warn(`${value} market not supported`);

		this.marketService.updateMarket(value);
	}

	public toggleLanguage() {
		const inactiveLanguage = this.currentMarket.Languages.find((language) => language !== this.currentLanguage)!;
		if (isSupportedLanguage(inactiveLanguage)) {
			this.marketService.updateLanguage(inactiveLanguage);
		}
	}

	private getMarketData(markets: Market[]): Market {
		return markets.find((market) => market.ID === this.marketService.currentMarket)!;
	}

	public handleCookieSettingsClick() {
		window.Cookiebot?.renew(); // Cookiebot is active only in production
	}
}
