import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { Observable, ReplaySubject, map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HasPermissionDirective } from '../../directives/has-permission.directive';
import { MaxNumberPipe } from '../../pipes/max-number.pipe';
import { AuthService } from '../../services/auth.service';
import { CartOldService } from '../../services/cart/cart-old.service';
import { CartService } from '../../services/cart/cart.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EpiService } from '../../services/epi/epi.service';
import { EventBusService } from '../../services/event-bus.service';
import { MarketService } from '../../services/market.service';
import { NotificationService } from '../../services/notification.service';
import { PunchoutService } from '../../services/punchout.service';
import { UserService } from '../../services/user/user.service';
import { FeatureToggles } from '../../types/configuration.types';
import { AppData } from '../../types/translations.types';
import { ButtonComponent } from '../button/button.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FlipPanelComponent } from '../flip-panel/flip-panel.component';
import { PunchoutBannerComponent } from '../punchout-banner/punchout-banner.component';
import { SearchHeaderInputComponent } from '../search-header-input/search-header-input.component';
import { MenuComponent } from './components/menu/menu.component';

@Component({
	selector: 'cramo-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		PunchoutBannerComponent,
		MatIconModule,
		SearchHeaderInputComponent,
		RouterLink,
		ButtonComponent,
		FlipPanelComponent,
		ContactUsComponent,
		MaxNumberPipe,
		MenuComponent,
		HasPermissionDirective,
	],
})
export class HeaderComponent implements OnInit, OnDestroy {
	public currentLanguage: string;
	public isLoggedIn$: Observable<boolean>;
	public showMenu: boolean;
	public quantityLoading = false;
	public scrollDown = false;
	public isMobile = false;
	public isPunchout$: Observable<boolean>;
	public isMainMenuVisible: Signal<boolean>;

	public registrationLink: string;

	private onDestroy$ = new ReplaySubject(1);
	private canOpenSideNav = true;
	private prevScrollPos: number;
	private menuHeight = 106;

	public appData: Signal<AppData>;
	public featureToggles: Signal<FeatureToggles>;
	public notificationCount: Signal<number>;

	/**
	 * @deprecated is replaced by cartItemCount in new cart.
	 */
	public cartItems: Signal<{ cartCount: number; isLoading: boolean } | undefined>;

	public cartItemCount: Signal<number>;

	constructor(
		private cartService: CartService,
		public cartOldService: CartOldService,
		private eventBusService: EventBusService,
		private notificationService: NotificationService,
		private epiService: EpiService,
		private marketService: MarketService,
		private authService: AuthService,
		private breakpointObserver: BreakpointObserver,
		private userService: UserService,
		private punchoutService: PunchoutService,
		private configurationService: ConfigurationService,
	) {
		this.featureToggles = toSignal(this.configurationService.featureToggles$, { requireSync: true });
		this.appData = toSignal(this.epiService.appData$, { requireSync: true });
		const notifications = toSignal(this.notificationService.notifications$);
		this.notificationCount = computed(() => notifications()?.length ?? 0);
		this.cartItems = toSignal(cartOldService.cartCount$);
		this.cartItemCount = toSignal(this.cartService.cartItemCount$, { initialValue: 0 });
		this.isLoggedIn$ = this.userService.isLoggedIn$;
		this.isMainMenuVisible = toSignal(this.epiService.isMainMenuVisible$, { requireSync: true });
		this.registrationLink = this.epiService.getRegistrationLinkUrl();
	}

	@HostListener('window:scroll', [])
	public onWindowScroll() {
		const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		this.scrollDown = !this.showMenu && currentScrollPos > this.prevScrollPos && currentScrollPos > this.menuHeight;
		if (this.scrollDown) {
			this.epiService.changeMainMenuVisibility('close');
		}
		this.prevScrollPos = currentScrollPos;
	}

	ngOnInit() {
		this.prevScrollPos = window.pageYOffset;
		this.currentLanguage = this.marketService.currentLanguage;

		this.userService.user$.subscribe(() => {
			this.isPunchout$ = this.punchoutService.isPunchout$;
		});

		this.eventBusService.userSideNavToggleSubject.pipe(takeUntil(this.onDestroy$)).subscribe((state: boolean) => {
			this.canOpenSideNav = !state;
		});

		this.breakpointObserver
			.observe('(max-width: 767px)')
			.pipe(map((result) => result.matches))
			.subscribe((isMobile) => (this.isMobile = isMobile));
	}

	public toggleMainMenuVisibility() {
		this.epiService.changeMainMenuVisibility('toggle');
	}

	public login(event: Event) {
		event.stopPropagation();
		this.authService.login();
	}

	public toggleUserSideNav() {
		if (this.canOpenSideNav === true) {
			this.eventBusService.userSideNavToggleSubject.next(true);
		}
	}

	public hideMenu() {
		this.epiService.changeMainMenuVisibility('close');
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
