<div
	class="search-menu-container page-wrapper fcolumn-start-stretch"
	[cramoClickOutside]="searchString.length > 1"
	(byOutsideClicked)="close()"
	data-test-id="header-search-container"
>
	<div class="search-container" (keyup.enter)="goToSearchPage($event, searchFor.value)" (keyup.esc)="closeAndReset()">
		<input
			type="search"
			spellcheck="false"
			id="search-input-1"
			autocomplete="no"
			class="search-input"
			#searchFor
			[(ngModel)]="searchString"
			(ngModelChange)="searchChanged($event)"
			data-test-id="header-search-input"
		/>
		@if (showSearchDropdown) {
			<button class="close-icon-container" (click)="closeAndReset()">
				<cramo-icon iconName="close" [size]="20" />
			</button>
		}
		<button
			class="search-icon-container"
			(keyup.enter)="goToSearchPage($event, searchFor.value)"
			(click)="goToSearchPage($event, searchFor.value)"
			[disabled]="searchString.length < 2"
			data-test-id="header-search-button"
		>
			<cramo-icon iconName="search" [size]="20" />
		</button>
	</div>
	@if (showSearchDropdown) {
		<div class="search-dropdown fcolumn-start-stretch" data-testid="header-search-result-container">
			<div class="search-result-container">
				@if (searchResults && searchResults.ProductContentSearchResult.length > 0 && !isSearching) {
					<div>
						<div class="search-result-type-header" tabindex="-1">{{ appData().search.productResultLabel }}</div>
						@for (product of searchResults?.ProductContentSearchResult; track $index) {
							<div class="search-result-row" data-testid="header-search-row-product">
								<a [routerLink]="product.LinkUrl" (click)="registerAndcloseAndReset(searchFor.value, product.LinkUrl)">
									<div class="frow-start-center">
										<img class="product-image" [src]="product.ImageUrl" alt="Product image" />
										<span>{{ product.Description }}</span>
									</div>
								</a>
							</div>
						}
						<!-- TODO: Click area should be bigger -->

						<div class="search-result-row">
							<a href="#" (click)="goToSearchPage($event, searchFor.value)" data-test-id="header-search-show-more">
								<span>{{ appData().search.showMoreProductsLabel }}</span>
							</a>
						</div>
					</div>
				}

				@if (searchResults && searchResults.ContentSearchResult.length > 0 && !isSearching) {
					<div>
						<div class="search-result-type-header" tabindex="-1">{{ appData().search.contentResultLabel }}</div>
						@for (content of searchResults?.ContentSearchResult; track $index) {
							<div class="search-result-row">
								<cramo-link
									[url]="content.LinkUrl"
									(click)="registerAndcloseAndReset(searchFor.value, content.LinkUrl)"
								>
									<span>{{ content.Header }}</span>
								</cramo-link>
							</div>
						}
					</div>
				}

				@if (isSearching) {
					<div class="loader-container">
						<cramo-loading-area [showTransparent]="true" [title]="appData().search.searchProgressText" />
					</div>
				}

				@if (emptyResult) {
					<div class="search-result-row no-result" tabindex="-1">
						<span>{{ appData().search.noResultFoundText }}</span>
					</div>
				}
			</div>
		</div>
	}
</div>
