@if (user()) {
	<div class="no-available-customers-container" *ngIf="viewData$ | async as viewData">
		<cramo-language-button />

		<img src="/assets/images/cramo-logo.svg" alt="Cramo" width="200" height="34" class="cramo-logo" />

		<h2>{{ appData().onboarding.noAvailableCustomersHeader }}</h2>

		<p>{{ description() | async }}</p>

		@if (user()?.CustomerInfo?.MarketId) {
			<p>{{ defaultCustomerDescription() }}</p>
		}

		<ng-container *ngIf="marketOptions$ | async as marketOptions">
			<p>{{ appData().onboarding.noAvailableCustomersSelectMarketDescription }}</p>

			<div class="market-selector">
				@for (marketOption of marketOptions; track $index) {
					<button mat-stroked-button style="height: 55px" (click)="goToMarket(marketOption.id)">
						<div class="market-option">
							<img class="market-flag" [src]="marketOption.flagImageUrl" alt="" />
							<div>{{ marketOption.label }}</div>
						</div>
					</button>
				}
			</div>
		</ng-container>

		<div class="buttons">
			<cramo-button [text]="appData().framework.signOutText" (onclick)="logout()" />

			@let defaultCustomerMarketId = user()?.CustomerInfo?.MarketId;

			@if (defaultCustomerMarketId) {
				<cramo-button
					[text]="appData().onboarding.noAvailableCustomersConfirmSwitchMarketButtonText"
					(onclick)="goToMarket(defaultCustomerMarketId)"
					buttonColor="primary"
				/>
			}
		</div>
	</div>
}
